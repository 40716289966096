.Mission {
    text-align: center;
    color: #1D3144;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.Banner {
    position: relative;
    top: -30px;
    padding: 20px;
    width: 70%;
    background-color: #ffffff;
    border-radius: 25px;
    border: 1px solid #1D3144;
    border-radius: 25px;
}

.Offerings {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0px 20px 20px 20px;
}

.Card {
    margin: 20px;
    width: 300px;
    @media (min-width:1025px) {
        width: 400px !important;
    } 
}

.CardText {
    text-align: left;
}

.CardIcon {
    font-size: 5rem !important;
}