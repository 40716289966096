.Home {
    background-image: url("../../Assets/bridge-home.jpg");
    box-shadow: 0px 0px 0px 0px #00000040,inset 0 0 0 1000px rgba(0,0,0,.5);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    max-height: 600px;
    width: 100%;
    color: #FFFFFF;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 450px) {
    .Home {
        background-image: none;
        background-color: #1D3144;
    }
}